import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';  // Make sure this path correctly points to your App component file
import './index.css'; // Optionally import a global CSS file for any base styles

const root = ReactDOM.createRoot(document.getElementById('root')); // Get the root DOM element

root.render(
  <React.StrictMode>
    <App />  {/* Render the main App component */}
  </React.StrictMode>
);
