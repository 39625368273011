import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { image1, image2, image3 } from '../images';

const ExperienceSection = styled.section`
  padding: 80px 20px;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 100%;
  min-height: 100vh; /* Adjusted for responsiveness */
`;

const ExperienceContainer = styled(motion.div)`
  display: flex;
  align-items: center; /* Centers items vertically */
  justify-content: space-between;
  width: 90%;
  max-width: 1200px; /* Limits max width for large screens */
  margin: 40px 0;
  padding: 20px;
  position: relative;
  overflow: visible; /* Allow overflow for scaling effects */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items on smaller screens */
    align-items: center;
    padding: 0; /* Adjust padding for small screens */
    gap: 20px; /* Ensure consistent spacing between elements */
  }
`;

const ExperienceEntry = styled(motion.div)`
  background-color: ${({ theme }) => theme.body};
  border: 2px solid #ddd;
  border-radius: 20px;
  padding: 20px;
  width: 48%;
  max-width: 500px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin: 10px;
  z-index: 2;
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px) scale(1.03);
  }

  @media (max-width: 768px) {
    width: 100%; /* Make the width 100% to match the container width */
    max-width: 250px; /* Same as image width */
    margin: 10px 0; /* Reduced margin for tighter spacing */
  }
`;


const BackgroundImage = styled(motion.img)`
  width: 100%;
  max-width: 350px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  opacity: 0.8;
  transition: transform 0.3s ease, opacity 0.3s ease;
  margin: 10px 0; /* Adjust margin for better spacing */
  flex-shrink: 0; /* Prevent images from shrinking */

  &:hover {
    transform: scale(1.05);
    opacity: 1;
  }

  @media (max-width: 768px) {
    max-width: 250px; /* Ensure it matches the entry width */
    margin: 50px 0 0; /* make sure images are vertically centered */

  }
`;


const Experience = () => {
  const experiences = [
    {
      title: 'Further Vocational Training in Information and Communication Technology',
      details: `Graduated from Grit:lab under Åland Yrkesgymnasium. The program, running from 2022-2024, specialized in Cybersecurity and was based on the 01Edu peer-to-peer learning curriculum. Key areas of study included working in an operational environment for ICT, software design and development, software testing, and real-world software development projects, particularly in full-stack development and cybersecurity.`,
    },
    {
      title: 'Hackathons and Challenges',
      details: `Participated in two PAF company hackathons, contributing innovative solutions in competitive environments. Additionally, I engaged in cybersecurity challenges on platforms like Hack The Box and Root-Me, honing my skills in real-world scenarios.`,
    },
    {
      title: 'Freelance Projects',
      details: `Currently developing a web app designed as a companion tool for a competitive dice board game, featuring scorekeeping and real-life event notifications. Additionally, I provided IT support and conducted penetration testing for a small company in Helsinki, improving their security posture and operational efficiency.`,
    },
  ];

  const images = [
    { src: image1, rotate: '5deg' },
    { src: image2, rotate: '-10deg' },
    { src: image3, rotate: '15deg' },
  ];

  return (
    <ExperienceSection id="experience">
      <h2>Experience</h2>
      {experiences.map((experience, index) => (
        <ExperienceContainer
          key={index}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: index * 0.3 }}
        >
          <ExperienceEntry
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <h3>{experience.title}</h3>
            <p>{experience.details}</p>
          </ExperienceEntry>
          <motion.div
            style={{ transform: `rotate(${images[index].rotate})` }}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 0.7, scale: 1 }}
            transition={{ duration: 1.5, delay: index * 0.5 }}
          >
            <BackgroundImage src={images[index].src} alt={`Experience Background ${index}`} />
          </motion.div>
        </ExperienceContainer>
      ))}
    </ExperienceSection>
  );
};

export default Experience;
