// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAp6XN6d5OQUUQaRdjvXnUHVQRTD6X67qY",
  authDomain: "salam-web-cv.firebaseapp.com",
  projectId: "salam-web-cv",
  storageBucket: "salam-web-cv.appspot.com",
  messagingSenderId: "239250503600",
  appId: "1:239250503600:web:a7bffa336fa696a0614875",
  measurementId: "G-STB49TJ7XS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics };
