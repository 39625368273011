// Modal.jsx
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Backdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled(motion.div)`
  background: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh; /* Limits height to 80% of the viewport */
  overflow-y: auto; /* Allows scrolling if content is too tall */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  position: relative;

  /* Custom scrollbar for better visibility */
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.text};
`;

const ProjectLinkButton = styled.a`
  background-color: #007bff;
  color: #fff;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 0.9rem;

  &:hover {
    background-color: #0056b3;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const ProjectImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
`;

const ProjectImage = styled.img`
  max-width: 100%;
  max-height: 60vh; /* Allows the image to fill up to 60% of the viewport height */
  object-fit: contain; /* Ensures the image scales without distortion */
  border-radius: 5px;
`;

const Modal = ({ showModal, closeModal, project }) => {
  if (!showModal) return null;

  return (
    <Backdrop
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4 }} // Slightly slower fade-in for smoothness
      onClick={closeModal}
    >
      <ModalContent
        initial={{ y: '-50vh', opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: '50vh', opacity: 0 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }} // Smoother animation with easeInOut
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClick={closeModal}>&times;</CloseButton>
        <h3>{project.title}</h3>
        <p>{project.details}</p>
        <ButtonContainer>
          {project.githubLink && (
            <ProjectLinkButton href={project.githubLink} target="_blank" rel="noopener noreferrer">
              GitHub
            </ProjectLinkButton>
          )}
          {project.liveDemo && (
            <ProjectLinkButton href={project.liveDemo} target="_blank" rel="noopener noreferrer">
              Live Demo
            </ProjectLinkButton>
          )}
          {project.virusTotal && (
            <ProjectLinkButton href={project.virusTotal} target="_blank" rel="noopener noreferrer">
              Virus Total Report
            </ProjectLinkButton>
          )}
        </ButtonContainer>
        <ProjectImageContainer>
          <ProjectImage src={project.image} alt={project.title} />
        </ProjectImageContainer>
      </ModalContent>
    </Backdrop>
  );
};

export default Modal;
