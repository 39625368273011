import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import Modal from './Modal'; // Import the Modal component

const PortfolioSection = styled.section`
  padding: 60px 20px;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  max-width: 1200px; /* Constrain width for larger screens */
  margin: 0 auto; /* Center the section */
`;

const ProjectGrid = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
`;

const ProjectCard = styled(motion.div)`
  background-color: ${({ theme }) => theme.body};
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 768px) {
    padding: 40px;
    margin: 0; /* Remove margin here since we are using gap in grid */
  }
`;

const ProjectTitle = styled.h3`
  margin: 0;
  font-size: 1.5rem;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const ProjectDescription = styled.p`
  margin: 10px 0;
  color: ${({ theme }) => theme.text};
  font-size: 1rem;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const ExpandButton = styled(motion.button)`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
`;

const ExpandedContent = styled(motion.div)`
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.body};
`;

const ProjectLinks = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 10px;
`;

const ProjectLinkButton = styled.a`
  background-color: #007bff;
  color: #fff;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 0.9rem;

  &:hover {
    background-color: #0056b3;
  }
`;

const ProjectImage = styled.img`
  max-width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 5px;
  margin-top: 10px;

  @media (min-width: 768px) {
    max-height: 250px;
  }
`;

const Portfolio = () => {
  const [expandedProjectIndex, setExpandedProjectIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const toggleExpand = (index) => {
    if (window.innerWidth >= 768) {
      // On large screens, open the modal
      setSelectedProject(projects[index]);
      setShowModal(true);
    } else {
      // On small screens, toggle expansion
      setExpandedProjectIndex(index === expandedProjectIndex ? null : index);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedProject(null);
  };

  const projects = [
    {
      title: 'MalTrack',
      description: 'A Python-based malware analysis tool focused on file tracking and monitoring.',
      details:
        'MalTrack is designed to track and monitor files to detect and analyze malware activity on a system. It captures detailed logs of file changes and behaviors, which can help in identifying and understanding malware actions. The project utilizes technologies like Python and various libraries for system monitoring and logging.',
      githubLink: 'https://github.com/salam-github/MalTrack',
      liveDemo: '', // No live demo available for this project
      image: 'https://raw.githubusercontent.com/salam-github/MalTrack/master/images/whitelist.png',
    },
    {
      title: 'MalWare',
      description: 'A simulated ransomware program for educational purposes, demonstrating file encryption, anti-virus evasion, and dynamic key generation.',
      details:
        'This project involves creating a ransomware simulation to understand its inner workings in a controlled environment. The program encrypts files, generates a unique ransom note for each victim, and dynamically creates a decryption program. It employs advanced techniques to bypass anti-virus detection, achieving a low detection rate on VirusTotal. The ransomware uses dynamic key generation and obfuscation techniques, with a process to derive the decryption key from the code provided in the ransom note. Additional features include disabling Windows Defender and creating persistent tasks. This simulation offers deep insights into ransomware behavior, crucial for cybersecurity defense strategies.',
        virusTotal: 'https://www.virustotal.com/gui/file/2d0a2951923ee54b29dc681b0fff241ccf2d4345afd729de791c82f74131b6f7/detection',
      githubLink: 'https://github.com/salam-github/malware',
      image: 'https://i.imgur.com/P5HEDVw.jpeg', // Replace with your own image link
    },
    {
      title: 'Injector',
      description: 'A Go-based command-line tool for bundling multiple executables into a single file, with a focus on cybersecurity applications.',
      details:
      'INJECTOR is a tool designed to combine multiple executable files into a single bundled executable. It features a user-friendly interface with a splash screen and menu, guiding users through the bundling process. The program generates a temporary Go source file, compiles it, and appends the original executables to create a single file that can execute all bundled content sequentially. The tool leverages in-memory execution to run embedded executables, avoiding file system modifications. INJECTOR is useful for cybersecurity education, red team exercises, and secure software packaging, though it also highlights the importance of understanding the security implications of such tools.',
      githubLink: 'https://github.com/salam-github/InjectorGO',
      image: 'https://i.imgur.com/xXhgc6c.png', // Replace with your own image link
    },
    {
      title: '"Freeze" dice game buddy app',
      description: 'An ongoing web app project for tracking and calculating scores in the Dice Game, featuring real-time updates and cloud deployment.',
      details:
      'This ongoing project, currently being developed as freelance work, is a web application designed to calculate and track scores for a Dice Game. The app handles special game rules, such as instant wins with triple sixes and score resets with triple ones, while offering features like the "Undo Last Roll" button for correcting mistakes. Built with modern frontend technologies and hosted on Firebase, the app utilizes Google/Firebase Analytics to track gameplay statistics, including dice throws, game wins, and "Buy Game" clicks. Continuous deployment via GitHub Actions ensures that updates are validated and deployed automatically, highlighting cloud development practices and providing a seamless user experience.',
      liveDemo: 'https://dice-game-41956.web.app/',
      image: 'https://i.imgur.com/ZJ9sbRH.png', // Replace with your own image link
    },
    // Add more projects as needed
  ];

  return (
    <PortfolioSection id="portfolio">
      <h2>Portfolio</h2>
      <ProjectGrid>
        {projects.map((project, index) => (
          <ProjectCard key={index}>
            <ProjectTitle>{project.title}</ProjectTitle>
            <ProjectDescription>{project.description}</ProjectDescription>
            <ExpandButton onClick={() => toggleExpand(index)}>
              {window.innerWidth >= 768 ? 'See Details' : (expandedProjectIndex === index ? 'Collapse' : 'Expand')}
            </ExpandButton>
            <AnimatePresence>
              {expandedProjectIndex === index && window.innerWidth < 768 && (
                <ExpandedContent
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <p>{project.details}</p>
                  <ProjectImage src={project.image} alt={project.title} />
                  <ProjectLinks>
                    {project.githubLink && (
                      <ProjectLinkButton href={project.githubLink} target="_blank">
                        GitHub
                      </ProjectLinkButton>
                    )}
                    {project.liveDemo && (
                      <ProjectLinkButton href={project.liveDemo} target="_blank">
                        Live Demo
                      </ProjectLinkButton>
                    )}
                    {project.virusTotal && (
                      <ProjectLinkButton href={project.virusTotal} target="_blank">
                        Virus Total Report
                      </ProjectLinkButton>
                    )}
                  </ProjectLinks>
                </ExpandedContent>
              )}
            </AnimatePresence>
          </ProjectCard>
        ))}
      </ProjectGrid>
      <Modal showModal={showModal} closeModal={closeModal} project={selectedProject} />
    </PortfolioSection>
  );
};

export default Portfolio;
