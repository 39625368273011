import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './styles/theme';
import GlobalStyles from './styles/GlobalStyles';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Skills from './components/Skills';
import Portfolio from './components/Portfolio';
import Experience from './components/Experience';
import Contact from './components/Contact';
import { analytics } from './firebase'; // Import your Firebase analytics
import { logEvent } from 'firebase/analytics'; // Import logEvent

const MainContent = styled.main`
  padding: 60px 20px;
`;

const App = () => {
  const [theme, setTheme] = useState('light');

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    // Log the page view when the App component mounts
    logEvent(analytics, 'page_view', {
      page_title: 'Main Page',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyles />
      <Header toggleTheme={toggleTheme} theme={theme} />
      <MainContent>
        <Hero />
        <About />
        <Skills />
        <Portfolio/>
        <Experience/>
        <Contact/>
        {/* Add more sections */}
      </MainContent>
    </ThemeProvider>
  );
};

export default App;
