import React from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import { FlipWords } from './FlipWords'; // Import the FlipWords component

const waveAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const HeroSection = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: ${({ theme }) =>
    theme.body === '#FFFFFF'
      ? 'linear-gradient(to bottom right, #0066cc, #3399ff)'
      : 'linear-gradient(to bottom right, #333333, #111111)'};
  background-size: 200% 200%;
  animation: ${waveAnimation} 10s ease infinite;
  color: ${({ theme }) => theme.text};
  text-align: center;
`;

const HeroText = styled(motion.h1)`
  font-size: 3rem;
  margin-bottom: ${({ isSecondLine }) => (isSecondLine ? '10px' : '20px')};
  color: ${({ theme }) => theme.text};
`;

const HeroSubText = styled.div`
  font-size: 1.5rem;
  max-width: 600px;
  margin-bottom: 30px;
  position: relative;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CallToAction = styled(motion.button)`
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.primaryHover};
  }
`;

const Hero = ({ theme }) => {
  const words = [
    'Full Stack Developer',
    'React Enthusiast',
    'Cybersecurity Specialist',
    'Creative Problem Solver',
    'Freelance Photographer',
  ];

  return (
    <HeroSection theme={theme}>
      <HeroText
        initial={{ y: -220 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.2, type: 'spring', stiffness: 120 }}
      >
        Hi, my name is Salam
      </HeroText>
      <HeroText
        isSecondLine
        initial={{ y: -250 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.4, type: 'spring', stiffness: 90 }}
      >
        I'm a
      </HeroText>
      <HeroSubText>
        <FlipWords words={words} duration={3000} />
      </HeroSubText>
      <CallToAction
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}
        theme={theme}
      >
        Get in Touch
      </CallToAction>
    </HeroSection>
  );
};

export default Hero;
