import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Poppins:wght@400;700&family=Roboto:wght@400;700&display=swap');

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'Roboto', sans-serif;
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: background-color 0.3s ease, color 0.3s ease;
    line-height: 1.6;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    color: ${({ theme }) => theme.text};
    margin-bottom: 20px;
    font-weight: 700;
  }

  p {
    margin-bottom: 20px;
    font-size: 1.1rem;
    line-height: 1.8;
  }

  button {
    cursor: pointer;
    background-color: ${({ theme }) => theme.text};
    color: ${({ theme }) => theme.body};
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;

    &:hover {
      background-color: #007bff;
      color: #fff;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    transition: color 0.3s ease;

    &:hover {
      color: #007bff;
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
`;

export default GlobalStyles;
