import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaGithub, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from 'react-google-recaptcha';


const ContactSection = styled.section`
  padding: 60px 20px;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 60vh;

  @media (max-width: 768px) {
    padding: 40px 10px;
  }
`;

const ContactForm = styled(motion.form)`
  background-color: ${({ theme }) => theme.cardBackground};
  border: 2px solid ${({ theme }) => theme.borderColor};
  border-radius: 15px;
  padding: 40px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;

  @media (max-width: 768px) {
    padding: 30px 20px; /* Adjust padding for smaller screens */
    max-width: 90%; /* Reduce max width on smaller screens */
  }
`;

const InputField = styled.input`
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.inputBackground};
  color: ${({ theme }) => theme.text};
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary};
  }

  @media (max-width: 768px) {
    padding: 12px; /* Smaller padding for smaller screens */
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.inputBackground};
  color: ${({ theme }) => theme.text};
  font-size: 1rem;
  resize: none;
  height: 100px;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary};
  }

  @media (max-width: 768px) {
    padding: 12px; /* Smaller padding for smaller screens */
  }
`;

const SubmitButton = styled(motion.button)`
  padding: 15px 30px;
  background-color: ${({ theme }) => theme.primary};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.primaryHover};
  }

  @media (max-width: 768px) {
    width: 100%; /* Full width button on smaller screens */
    padding: 12px; /* Adjust padding for smaller screens */
  }
`;

const ContactDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */

  @media (max-width: 768px) {
    gap: 10px; /* Reduced gap for smaller screens */
  }
`;

const IconWrapper = styled(motion.a)`
  color: ${({ theme }) => theme.text};
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }

  @media (max-width: 768px) {
    font-size: 1.3rem; /* Smaller icon size for smaller screens */
  }
`;

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [lastSubmitTime, setLastSubmitTime] = useState(0);
  const [captchaValue, setCaptchaValue] = useState(null);

  // Create a reference for the reCAPTCHA component so we can reset it after message is sent
  const recaptchaRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    const currentTime = new Date().getTime();
    if (currentTime - lastSubmitTime < 300000) { // 5 minutes
      toast.error('Please wait before sending another message.', {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }

    if (!captchaValue) {
      toast.error('Please complete the CAPTCHA.', {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }

    setLoading(true);

    emailjs.send('salam-web-cv', 'template_004h32c', {
      from_name: name,
      from_email: email,
      message: message,
      'g-recaptcha-response': captchaValue,  // Send the CAPTCHA token
      to_name: 'Salam',
      reply_to: email,
    }, 'eyJ_KxGkx0Q8tegyG')
    .then((result) => {
      toast.success('Message sent successfully!', {
        position: "top-right",
        autoClose: 5000,
      });
      setName('');
      setEmail('');
      setMessage('');
      setCaptchaValue(null);
      setLastSubmitTime(currentTime);
      setLoading(false);

      // Reset the reCAPTCHA
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    }, (error) => {
      toast.error('Failed to send message, please try again.', {
        position: "top-right",
        autoClose: 5000,
      });
      setLoading(false);
    });
  };

  return (
    <ContactSection id="contact">
      <h2>Contact Me</h2>
      <ContactForm
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        onSubmit={handleSubmit}
      >
        <InputField
          type="text"
          placeholder="Your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <InputField
          type="email"
          placeholder="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextArea
          placeholder="Your Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        <ReCAPTCHA
          sitekey="6Lfz7zYqAAAAAC2vPkQ0-SopM0Zgo3-m8PVf-MBW"  // Use your reCAPTCHA v2 site key
          onChange={(value) => setCaptchaValue(value)}
          ref={recaptchaRef}  // Attach the reference
        />
        <InputField
          type="text"
          placeholder="Leave this field empty"
          style={{ display: 'none' }}  // Honeypot field
          onChange={(e) => { if (e.target.value) return; }}  // Simple bot detection
        />
        <SubmitButton
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
          type="submit"
          disabled={loading}
        >
          {loading ? 'Sending...' : 'Send Message'}
        </SubmitButton>
      </ContactForm>
      <ContactDetails>
        <IconWrapper href="https://github.com/salam-github" target="_blank">
          <FaGithub />
        </IconWrapper>
        <IconWrapper href="https://www.linkedin.com/in/abdou-foon/" target="_blank">
          <FaLinkedin />
        </IconWrapper>
        <IconWrapper href="mailto:salamfoon@gmail.com">
          <FaEnvelope />
        </IconWrapper>
      </ContactDetails>
      <ToastContainer />
    </ContactSection>
  );
};

export default Contact;