import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import Modal from 'react-modal';
import { FaMountain, FaBicycle, FaBasketballBall, FaCamera } from 'react-icons/fa';
import profileImage from '../images/headshot-circular.png';

// Modal styling
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    background: 'none',
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
};

const AboutSection = styled.section`
  padding: 60px 20px;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  width: 100%;
  text-align: center;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const ProfileImage = styled(motion.img)`
  border-radius: 50%;
  width: 180px;
  height: 180px;
  object-fit: cover;
  margin-bottom: 20px;
  border: 4px solid ${({ theme }) => theme.primary};
  cursor: pointer;

  @media (max-width: 480px) {
    width: 150px;
    height: 150px;
  }
`;

const EnlargedImage = styled(motion.img)`
  border-radius: 50%;
  width: 300px;
  height: 300px;
  object-fit: cover;
  border: 4px solid ${({ theme }) => theme.primary};
`;

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const TabButton = styled.button`
  padding: 10px 20px;
  margin: 0 5px;
  background-color: ${({ theme, active }) => (active ? theme.primary : theme.cardBackground)};
  color: ${({ theme, active }) => (active ? '#fff' : theme.text)};
  border: 2px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex: 1;

  &:hover {
    background-color: ${({ theme }) => theme.primaryHover};
    color: #fff;
  }

  @media (max-width: 480px) {
    margin: 5px 0;
  }
`;

const AboutText = styled(motion.div)`
  font-size: 1.2rem;
  line-height: 1.8;
  margin-top: 20px;
  width: 100%;
`;

const SectionTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.primary};
`;

const ExpandButton = styled(motion.button)`
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.primary};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.primaryHover};
  }
`;

const HobbiesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
`;

const HobbyItem = styled(motion.li)`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  margin-bottom: 15px;

  svg {
    color: ${({ theme }) => theme.primary};
    margin-right: 10px;
  }
`;

const EducationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EducationCard = styled(motion.div)`
  background-color: ${({ theme }) => theme.cardBackground};
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 450px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const EducationTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.primary};
`;

const EducationDetails = styled.p`
  font-size: 1.1rem;
  margin-bottom: 5px;
`;

const About = () => {
  const [activeTab, setActiveTab] = useState('bio');
  const [isExpanded, setIsExpanded] = useState(false);
  const [isImageEnlarged, setIsImageEnlarged] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleImageClick = () => {
    setIsImageEnlarged(true);
    document.body.style.overflow = 'hidden'; // Disable scrolling
  };

  const handleCloseModal = () => {
    setIsImageEnlarged(false);
    document.body.style.overflow = 'auto'; // Re-enable scrolling
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'bio':
        return (
          <>
            <SectionTitle>Who I Am</SectionTitle>
            <AboutText
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              I am a recent graduate from Grit:lab, a cutting-edge coding school powered by the 01 Edu platform. My education was deeply rooted in a peer-to-peer learning environment, where the ability to learn independently and collaborate with others was key to progressing through challenging projects. This experience cultivated strong problem-solving skills, resilience, and a passion for continuous learning—qualities that I carry into my professional work.
            </AboutText>
            <AnimatePresence>
              {isExpanded && (
                <>
                  <SectionTitle>Core Expertise</SectionTitle>
                  <AboutText
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    My core expertise spans Rust, Go, JavaScript, and Python, with a particular focus on building secure web applications and safeguarding data. I’m passionate about penetration testing, malware analysis, and all facets of cybersecurity. As a web developer, I specialize in React as my primary framework, and I’m proficient in Node.js, Docker, and AWS, enabling me to create scalable and secure solutions.
                  </AboutText>
                  <SectionTitle>Education</SectionTitle>
                  <AboutText
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    At Grit:lab, I was immersed in a project-based curriculum that emphasized both technical skills and essential soft skills like critical thinking, communication, and teamwork. The hands-on approach taught me not just how to code, but how to approach complex problems creatively and collaboratively—skills that are invaluable in the tech industry.
                  </AboutText>
                  <SectionTitle>Future Goals</SectionTitle>
                  <AboutText
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    I am deeply interested in both web development and cybersecurity. My goal is to work in an environment where I can continue to grow and learn every day, taking on challenges that push me to innovate and improve. The rigorous training I received at Grit:lab has prepared me to thrive in roles that demand problem-solving, resilience, and a commitment to excellence.
                  </AboutText>
                </>
              )}
            </AnimatePresence>
            <ExpandButton
              onClick={toggleExpand}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              {isExpanded ? 'Collapse' : 'Expand'}
            </ExpandButton>
          </>
        );
      case 'education':
        return (
          <EducationContainer>
            <EducationCard
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <EducationTitle>Education</EducationTitle>
              <EducationDetails>
                At Grit:lab, I was immersed in a project-based curriculum that emphasized both technical skills and essential soft skills like critical thinking, communication, and teamwork. The hands-on approach taught me not just how to code, but how to approach complex problems creatively and collaboratively—skills that are invaluable in the tech industry.
              </EducationDetails>
            </EducationCard>
          </EducationContainer>
        );
      case 'hobbies':
        return (
          <HobbiesList>
            <HobbyItem
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <FaMountain />
              Bouldering - Climbing indoors and outdoors, focusing on strength and technique.
            </HobbyItem>
            <HobbyItem
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <FaBicycle />
              Biking - Exploring trails, commuting to work and staying fit on my road bike.
            </HobbyItem>
            <HobbyItem
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <FaBasketballBall />
              Basketball - Playing pickup games and following the NBA closely.
            </HobbyItem>
            <HobbyItem
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <FaCamera />
              Photography - Capturing landscapes and moments, particularly at events.
            </HobbyItem>
          </HobbiesList>
        );
      default:
        return null;
    }
  };

  return (
    <AboutSection id="about">
      <ContentWrapper>
        <ProfileImage 
          src={profileImage} 
          alt="Profile"
          onClick={handleImageClick}
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        />
        <Tabs>
          <TabButton active={activeTab === 'bio'} onClick={() => setActiveTab('bio')}>
            Biography
          </TabButton>
          <TabButton active={activeTab === 'education'} onClick={() => setActiveTab('education')}>
            Education
          </TabButton>
          <TabButton active={activeTab === 'hobbies'} onClick={() => setActiveTab('hobbies')}>
            Hobbies
          </TabButton>
        </Tabs>
        {renderContent()}
      </ContentWrapper>

      <Modal
        isOpen={isImageEnlarged}
        onRequestClose={handleCloseModal}
        style={customStyles}
        contentLabel="Enlarged Profile Image"
        closeTimeoutMS={300} // Adding a delay for modal closing animation
        ariaHideApp={false} // Disable this if you aren't setting the app element for accessibility
      >
        <EnlargedImage 
          src={profileImage} 
          alt="Enlarged Profile"
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.5, opacity: 0 }}
          transition={{ duration: 0.3 }}
          onClick={handleCloseModal}
        />
      </Modal>
    </AboutSection>
  );
};

export default About;
