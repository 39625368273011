import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar = styled.nav`
  position: sticky;
  top: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.body};
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 10px 20px; /* Adjust padding for smaller screens */
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none; /* Hide the links by default on small screens */
  }
`;

const StyledNavLink = styled(Link)`
  margin: 0 15px;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #007bff;
  }
`;

const MobileMenuIcon = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block; /* Show the hamburger icon on small screens */
    font-size: 1.5rem;
    cursor: pointer;
  }
`;

const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100vh;
  background-color: ${({ theme }) => theme.body};
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2); /* Enhanced border effect */
  z-index: 999;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  padding-top: 60px;

  @media (min-width: 769px) {
    display: none; /* Hide mobile menu on larger screens */
  }
`;

const MobileNavLink = styled(Link)`
  margin: 20px 0;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #007bff;
  }
`;

const ToggleButton = styled.button`
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  border: 2px solid ${({ theme }) => theme.text};
  border-radius: 50%;
  padding: 5px;
  margin-left: 20px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.text};
    color: ${({ theme }) => theme.body};
  }

  @media (max-width: 768px) {
    margin: 20px 0;
  }
`;

const Header = ({ toggleTheme, theme }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <Navbar>
      <h1>Salam/Web-CV</h1>
      <NavLinks>
        <StyledNavLink to="about" smooth={true} duration={500}>
          About
        </StyledNavLink>
        <StyledNavLink to="skills" smooth={true} duration={500}>
          Skills
        </StyledNavLink>
        <StyledNavLink to="portfolio" smooth={true} duration={500}>
          Portfolio
        </StyledNavLink>
        <StyledNavLink to="experience" smooth={true} duration={500}>
          Experience
        </StyledNavLink>
        <StyledNavLink to="contact" smooth={true} duration={500}>
          Contact
        </StyledNavLink>
        <ToggleButton onClick={toggleTheme}>
          {theme === 'light' ? '🌙' : '☀️'}
        </ToggleButton>
      </NavLinks>

      {/* Mobile Menu Icon */}
      <MobileMenuIcon onClick={handleMenuToggle}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </MobileMenuIcon>

      {/* Mobile Menu */}
      <MobileMenu isOpen={isMenuOpen} ref={menuRef}>
        <MobileNavLink to="about" smooth={true} duration={500} onClick={handleMenuToggle}>
          About
        </MobileNavLink>
        <MobileNavLink to="skills" smooth={true} duration={500} onClick={handleMenuToggle}>
          Skills
        </MobileNavLink>
        <MobileNavLink to="portfolio" smooth={true} duration={500} onClick={handleMenuToggle}>
          Portfolio
        </MobileNavLink>
        <MobileNavLink to="experience" smooth={true} duration={500} onClick={handleMenuToggle}>
          Experience
        </MobileNavLink>
        <MobileNavLink to="contact" smooth={true} duration={500} onClick={handleMenuToggle}>
          Contact
        </MobileNavLink>
        <ToggleButton onClick={toggleTheme}>
          {theme === 'light' ? '🌙' : '☀️'}
        </ToggleButton>
      </MobileMenu>
    </Navbar>
  );
};

export default Header;
