export const lightTheme = {
  body: '#FFFFFF',
  text: '#333333',
  toggleBorder: '#FFFFFF',
  background: '#363537',
  cardBackground: '#FFFFFF', // Background color for the contact form
  borderColor: '#DDDDDD', // Border color for inputs and form
  inputBackground: '#F5F5F5', // Input field background
  primary: '#007bff', // Primary button color
  primaryHover: '#0056b3' // Hover color for primary button
};

export const darkTheme = {
  body: '#333333',
  text: '#FAFAFA',
  toggleBorder: '#6B8096',
  background: '#999',
  cardBackground: '#444444', // Darker background for the contact form
  borderColor: '#555555', // Border color for inputs and form in dark mode
  inputBackground: '#555555', // Input field background for dark mode
  primary: '#1E90FF', // Primary button color for dark mode
  primaryHover: '#1C86EE' // Hover color for primary button in dark mode
};
