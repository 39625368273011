import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const SkillsSection = styled.section`
  padding: 60px 20px;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SkillsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;

const SkillContainer = styled.div`
  background-color: ${({ theme }) => theme.cardBackground};
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const SkillName = styled.p`
  margin-bottom: 10px;
  font-weight: bold;
`;

const SkillBarBackground = styled.div`
  background-color: ${({ theme }) => theme.borderColor};
  border-radius: 5px;
  overflow: hidden;
  height: 20px;
`;

const SkillBar = styled(motion.div)`
  background-color: #007bff;
  height: 100%;
  border-radius: 5px;
`;

const Skills = () => {
  const skillSet = [
    { name: 'GO', level: 65 },
    { name: 'Rust', level: 70 },
    { name: 'Python', level: 80 },
    { name: 'JavaScript', level: 65 },
    { name: 'React', level: 60 },
    { name: 'Node.js', level: 55 },
    { name: 'Cybersecurity', level: 85 },
    { name: 'Docker', level: 45 },
    { name: 'DevOps', level: 35 },
    { name: 'Unix/Linux Systems', level: 90 },
  ];

  return (
    <SkillsSection id="skills">
      <h2>Skills</h2>
      <SkillsContainer>
        {skillSet.map((skill) => (
          <SkillContainer key={skill.name}>
            <SkillName>{skill.name}</SkillName>
            <SkillBarBackground>
              <SkillBar
                initial={{ width: 0 }}
                animate={{ width: `${skill.level}%` }}
                transition={{ duration: 1.5 }}
              />
            </SkillBarBackground>
          </SkillContainer>
        ))}
      </SkillsContainer>
    </SkillsSection>
  );
};

export default Skills;
